.flexbox-container {
    display: flex;
    flex-direction: row;
    padding: 1%;
}
.content-card-div {
    width: 15%;
    padding: .5%;
}
.content-card {
    height:100%;
}